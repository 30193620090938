<template>
  <div class="fundModule">
    <rxNavBar :title="tltle"></rxNavBar>
    <!-- <rxNavBar
      v-if="androidFlg == false"
      :title="tltle"
      androidOrIOSFlag="true"
    ></rxNavBar>
    <rxNavBar v-else :title="tltle"></rxNavBar> -->
    <!-- <div class="chooseType">
      <div :style="index == chooseIndex ? tcolor1 : tcolor2" v-for="item,index in typeList" class="chooseTypeItem" :key="index">{{item.label}}</div>
      
    </div> -->
    <div class="searchRight">
      <div class="module-small" @click="showProject">
        <span class="project-name">{{ projectName }}</span>
        <img class="drop-down-image" src="../../assets/images/drop-down.png" />
      </div>
    </div>
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <van-picker
        show-toolbar
        :columns="projectDataInfo"
        value-key="val"
        @cancel="cancelChangeProject"
        @confirm="selectProject"
      />
    </van-popup>

    <div v-if="typeOf == 'hourse'" class="chufangCon">
      <div class="chufangConc">
        <div class="chufangConcTitleList">
          <div class="chufangConcTitle">
            <div class="chufangConcTitleItem11">排名</div>
            <div class="chufangConcTitleItem21">店组</div>
            <div class="chufangConcTitleItem31">目标</div>
            <div class="chufangConcTitleItem41">完成</div>
            <div class="chufangConcTitleItem51">完成率</div>
          </div>

          <div
            v-for="(items, indexs) in chufangList"
            :key="indexs"
            class="chufangConcTitle"
          >
            <div class="chufangConcTitleItem1">{{ indexs + 1}}</div>
            <div class="chufangConcTitleItem2">{{ items.departmentName}}</div>
            <div class="chufangConcTitleItem3">{{ csType == 'cf' ? (items.rentOutHouse ? items.rentOutHouse : 0) : (items.receiveHouse ? items.receiveHouse : 0) }}</div>
            <div class="chufangConcTitleItem4">{{ csType == 'cf' ? (items.actualRentOutHouse ? items.actualRentOutHouse : 0) : (items.actualReceiveHouse ? items.actualReceiveHouse : 0)}}</div>
            <div
              :style="Number(items.outHousel) >= 100 ? color1 : color2"
              class="chufangConcTitleItem5"
            >
              {{ String( csType == 'cf' ? (items.outHousel ? items.outHousel.toFixed(2) : 0 ): (items.inHousel ? items.inHousel.toFixed(2) :0) )  + "%" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="chufangCon">
      <div class="chufangConc">
        <div class="chufangConcTitleList">
          <div class="chufangConcTitle">
            <div class="chufangConcTitleItem1 fontweight">排名</div>
            <div class="chufangConcTitleItem6 fontweight">店组</div>
            <div class="chufangConcTitleItem3 fontweight">目标</div>
            <div class="chufangConcTitleItem7 fontweight">实际利差</div>
          </div>

          <div
            v-for="(items, indexs) in chufangList"
            :key="indexs"
            class="chufangConcTitle"
          >
            <div class="chufangConcTitleItem1">{{ indexs + 1 }}</div>
            <div class="chufangConcTitleItem6">{{ items.departmentName }}</div>
            <div class="chufangConcTitleItem3">{{ csType == 'sclc' ? ((items.interestMargin || items.interestMargin === 0)  ? items.interestMargin : '暂无数据') : ((items.averageInterestMargin || items.averageInterestMargin === 0) ? items.averageInterestMargin : '暂无数据') }}</div>
            <div
              :style="csType == 'sclc' ? (Number(items.actualInterestMargin) < items.interestMargin ? color3 : color2) : (Number(items.actualAverageInterestMargin) < items.averageInterestMargin ? color3 : color2)"
              class="chufangConcTitleItem7"
            >
              {{ csType == 'sclc' ? ((items.actualInterestMargin || items.actualInterestMargin === 0) ? items.actualInterestMargin : '暂无数据' ): ((items.actualAverageInterestMargin || items.actualAverageInterestMargin === 0) ? items.actualAverageInterestMargin : '暂无数据')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backButton" @click="backInfo">返回首页</div>
  </div>
</template>
<script>
import {
  DatetimePicker,
  Popup,
  Sticky,
  Picker,
  Tab,
  Tabs,
  TreeSelect,
  Col,
  Row,
  Button,
  List,
  Cell,
} from "vant";
import { queryType,queryInHome,queryOutHome,queryFirstLc,queryLc } from "../../getData/getData";
import {
  responseUtil,
  addMonth,
  getStaffId,
  checkAndroid,
  checkIOS,
} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
export default {
  name: "businessModule",
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
    [DatetimePicker.name]: DatetimePicker,
    [List.name]: List,
    [Cell.name]: Cell,
    rxNavBar,
  },
  data() {
    return {
      typeOf: "",
      androidFlg: "",
      titleFont: "大连2023年度目标",
      titletype: "进行中",
      csType: "",
      tltle: "",
      chooseIndex: 0,
      tcolor1: {
        "border-bottom": "2px solid #fe5e3a",
        color: "#fe5e3a",
      },
      tcolor2: {},
      chufangList: [
        
      ],
      color1: {
        color: "green",
      },
      color2: {
        color: "black",
      },
      color3: {
        color: "red",
      },
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: "",
      projectDataInfo: [
      ],
      projectId: "",
      formId: '',
    };
  },
  created() {
    this.csType = this.$route.query.cstype ? this.$route.query.cstype : "";
    this.typeOf = this.$route.query.typeOf ? this.$route.query.typeOf : "";
    this.formId = this.$route.query.form ? this.$route.query.form : '';
    console.log(this.csType, this.typeOf, "this.csType===");
  },
  mounted() {
    if (this.csType) {
      if (this.csType == "sf") {
        this.tltle = "收房目标";
      }
      if (this.csType == "cf") {
        this.tltle = "出房目标";
      }
      if (this.csType == "sclc") {
        this.tltle = "首次利差目标";
      }
      if (this.csType == "pjlc") {
        this.tltle = "平均利差目标";
      }
    }
    // if (this.typeOf) {
    //   if (this.typeOf == "hourse") {
    //   }
    //   if (this.typeOf == "licha") {
    //   }
    // }
    this.initData();
     setTimeout(() => {
        this.initTypeInfo()
      },500)
    // this.checkPhoneorMobel();
  },
  methods: {
    initData() {
      var that = this;
      const params = {
        user_id: getStaffId(),
        id: that.formId,
      };
      queryType(params).then((res) => {
        responseUtil.dealResponse(that, res, () => {
          that.projectDataInfo = res.data.data.types
          that.projectName = that.projectDataInfo[0].val
          that.projectId = that.projectDataInfo[0].code
          // console.log(that.projectName,that.projectId,"that.projectId===")
         
        });
      });
      
      
    },
    initTypeInfo() {
      if(this.csType == "sf") {
        this.searchinHourse()
      }
      if(this.csType == "cf") {
        this.searchoutHourse()
      }
      if(this.csType == "sclc") {
        this.searchsclcHourse()
      }
      if(this.csType == "pjlc") {
        this.searchpjlcHourse()
      }
    },
    // 首次
    searchsclcHourse() {
      
      var that = this
      const params = {
        user_id: getStaffId(),
        type: that.projectId,
        id: that.formId
      }
      queryFirstLc(params).then(res => {
        responseUtil.dealResponse(that, res, () => {
          that.chufangList = res.data.data.firstLicha
          // console.log(res.data.data, "sdadsdsadin===");
        });
      })
    },
    // 平均
    searchpjlcHourse() {
      
      var that = this
      const params = {
        user_id: getStaffId(),
        type: that.projectId,
        id: that.formId
      }
      queryLc(params).then(res => {
        responseUtil.dealResponse(that, res, () => {
          that.chufangList = res.data.data.lc
          // console.log(res.data.data, "sdadsdsadin===");
        });
      })
    },
     // 收房
    searchinHourse() {
      var that = this
      const params = {
        user_id: getStaffId(),
        type: that.projectId,
        id: that.formId
      }
      queryInHome(params).then(res => {
        responseUtil.dealResponse(that, res, () => {
          that.chufangList = res.data.data.inHouse
          // console.log(res.data.data, "sdadsdsadin===");
        });
      })
    },
    // 出房
    searchoutHourse() {
      var that = this
      const params = {
        user_id: getStaffId(),
        type: that.projectId,
        id: that.formId
      }
      queryOutHome(params).then(res => {
        responseUtil.dealResponse(that, res, () => {
          that.chufangList = res.data.data.outHouse
          // console.log(res.data.data, "sdadsdsadout===");
        });
      })
    },
    checkPhoneorMobel() {
      if (checkAndroid()) {
        this.androidFlg = true;
      } else if (checkIOS()) {
        this.androidFlg = false;
      }
    },
    showMore() {},
    backInfo() {
      this.$router.go(-2);
    },
    showProject() {
      this.isShowProjectMenu = true;
    },
    //选择项目
    selectProject(item) {
      this.projectName = item.val;
      this.projectId = item.code;
      this.isShowProjectMenu = false;
      this.initTypeInfo();
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = this.projectDataInfo[0].val;
      this.projectId = this.projectDataInfo[0].code;
      this.isShowProjectMenu = false;
      this.initTypeInfo();
    },
  },
};
</script>
<style scoped>
.fundModule {
  font-size: 12px;
  /* font-weight: bold; */
  background: #fafafa;
}
.mainList {
  margin: 0 10px;
  height: 500px;
  background: skyblue;
  border-radius: 10px;
}
.chooseType {
  height: 35px;
  background: #fff;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  margin: 0 10px 10px 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chooseTypeItem {
  /* padding-bottom: 4px; */
  padding: 4px 5px;
}

.chufangCon {
  margin: 5px 10px;
  height: 100%;
  /* background: pink; */
  display: flex;
  flex-direction: column;
}

.chufangConc {
  flex: 1;
  background: #fff;
  border-radius: 10px;
}
.chufangConb {
  height: 30px;
  /* background: #f60; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}
.chufangConcTitle {
  display: flex;
  height: 40px;
  font-size: 14px;
  /* padding:0 10px; */
  /* justify-content: space-around; */
  align-items: center;
}
.chufangConcTitleItem1 {
  width: 10%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem2 {
  width: 43%;
  height: 100%;
  /* line-height: 100%; */
  /* text-align: center; */
  /* line-height: 250%; */
  /* white-space: wrap; */
  /* overflow: hidden;
        text-overflow: ellipsis; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem3 {
  width: 15%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem4 {
  width: 15%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem5 {
  width: 17%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  /* border-bottom: 1px solid #FAFAFA; */
}
.chufangConcTitleItem6 {
  width: 55%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem7 {
  width: 20%;
  height: 100%;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem11 {
  width: 10%;
  height: 100%;
  line-height: 250%;
  white-space: wrap;
  font-weight: bold;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem21 {
  width: 43%;
  height: 100%;
  /* line-height: 100%; */
  /* text-align: center; */
  line-height: 250%;
  /* white-space: wrap; */
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem31 {
  width: 15%;
  height: 100%;
  line-height: 250%;
  white-space: wrap;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem41 {
  width: 15%;
  height: 100%;
  line-height: 250%;
  white-space: wrap;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  border-right: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.chufangConcTitleItem51 {
  width: 17%;
  height: 100%;
  white-space: wrap;
  /* overflow: hidden;
        text-overflow: ellipsis; */
  font-weight: bold;
  text-align: center;
  line-height: 250%;
  border-right: 1px solid #fafafa;
  /* border-bottom: 1px solid #FAFAFA; */
}
.chufangConcTitleList {
  /* border-left: 1px solid #FAFAFA;
        border-top: 1px solid #FAFAFA; */
  margin-top: 5px;
  /* border-radius: 10px; */
}
.fontweight {
  font-weight: bold;
}
.backButton {
  height: 40px;
  margin: 0 10px;
  background: linear-gradient(
    to right,
    rgba(255, 194, 116, 1),
    rgba(255, 93, 59, 1)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 8px;
  color: #fff;
}
.searchRight {
  /* width: 100%; */
  margin: 10px;
}
/*项目选择模块*/
.module-small {
  height: 36px;
  margin-right: 10px;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 30px;
  line-height: 36px;
}
/*项目名称样式*/
.project-name {
  font-size: 14px;
  color: #666666;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 18px;
  /*padding-bottom: 4px;*/
}
</style>
